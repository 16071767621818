import axios from 'axios';

function getWorkshops(){
  
    return axios({
        method: 'get',
        url: '/api/public/workshop.php'})
        .then(res => { return Promise.resolve(res);})
        .catch(error => { return Promise.reject(error);});

}

function getWorkshopInfo(workshopId){
        return axios({
        method: 'get',
        url: '/api/public/workshop.php',
        params:{id:workshopId}
        })
        .then(res => { return Promise.resolve(res);})
        .catch(error => { return Promise.reject(error);});
    
    }


export default{
    getWorkshops,
    getWorkshopInfo
};