<template>
    <div>
        <Header />
        <div class="index-2 mt-5 mx-auto col-sm-12	col-md-6 banner overflow-hidden">
            <div class="row mt-3  " v-if="!formSuccess">
                <div class="col-sm-8 col-8 col-md-6 mx-auto text-center">
                    <img class="w-100" :src="this.workshop.Image" />
                </div>
            </div>
        </div>
        <div v-if="!showForm" class="mt-3 index-2 mx-auto col-sm-12 col-xs-12	col-md-8	col-lg-8 col-xl-8">
            <div class="row d-flex justify-content-center">
                <div class="col-11">
                    
                    <div class="row mt-4">
                        <div class="col-1">
                        </div>
                        <div class="col-lg-11 col-xl-11 col-sm-12 col-xs-12 col-md-12">
                            <p class="home-text text-justify">{{workshop.Description}}</p>
                            <span class="workshop-subtitle mt-3">Duration: </span>
                            <span class="ml-2 home-text"> {{workshop.Duration}} hrs</span>

                            <p class="mt-3">
                            </p>
                                <span v-if="benefits.length>0" class="workshop-subtitle">Benefits: </span>
                            <p class="mt-3 home-text">
                                <ul v-if="benefits.length>0">
                                    <li v-for="(benefit, key) in benefits" :key="key">{{benefit}}</li>
                                </ul>
                            </p>

                            <span class="workshop-subtitle">Available Languages: </span>
                            <p class="mt-3 home-text">
                                <ul>
                                    <li v-for="(invest, key) in investment" :key="key">English / Spanish</li>
                                </ul>
                            </p>

                            
                            <span class="workshop-subtitle">Investment: </span>
                            <p class="mt-3 home-text">
                                <ul>
                                    <li v-for="(invest, key) in investment" :key="key">{{invest}} USD</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5 mt-5">
                
                <div class="col-md-6 col-9 m-auto d-flex justify-content-center">
                    <div class="m-auto text-center">
                        <button v-on:click="changeShowForm();" class="contact-us-button">Request Workshop <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                    </div>
                </div>
            
            </div>
           <div class="row mt-3 mb-4">
                <div class="col-4"></div>
                <div class="d-flex justify-content-center  col-4 p-0">
                    <div class="mr-2">
                       <a :href="Contacts.Whatsapp" target="_blank"> <img class="workshop-contact" src="@/assets/whatsapp.svg" /> </a>
                    </div>
                    <div class="">
                       <a :href="Contacts.Mobile" target="_blank"> <img class="workshop-contact" src="@/assets/telefono.svg" /> </a>
                    </div>
                </div>
                <div class="col-4"></div>
            </div>
            
        
        </div>


          <div v-if="formSuccess" class="row">
                    <div class="col-10 col-md-4 mx-auto ">
                        <div  class=" contact-us-button text-center mt-50">
                            Format sent!
                        </div>
                    </div>
            </div>

        <div v-if="showForm && !formSuccess" id="workshop-form" class="mt-3 index-2 mx-auto col-sm-12 col-xs-12	col-md-6 col-lg-6 col-xl-6">
        
               <div class="row">
                <div class="col-11">
                </div>
                  <div class="col-md-1 col-1">
                      <div v-on:click="showForm=false" class="clickable"> 
                          <img src="@/assets/tache.svg" class="remove-x">
                      </div>
                  </div>
            </div>  
            <div class="row mb-4">
                
                  <div class="col-1 d-flex justify-content-end px-0">
                        
                  </div>
                <div class="col-1 d-flex justify-content-start px-0">
                       
                  </div>
              </div>

            <div class="row">
               <!-- form -->
                <div class="col-12 mx-0">
                 <b-form>
            


                <div class="form-group">
                    <div class="row blue-form">
                <label for="name" class="col-4">*Name:</label>
                    <div class="col-8">
                    <input v-model="form.name" type="text" class="form-control" name="name" id="name">
                    </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form">
                <label for="email" class="col-4">*Email:</label>
                    <div class="col-8">
                    <input v-model="form.email" type="email" class="form-control" name="email" id="email">
                    </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="row blue-form">
                        <label for="mobile" label="*Mobile:" class="col-4">*Mobile:</label>
                            <div class="col-4 mr-0"> 
                                <select v-model="form.countryCode" class="menu-select py-2 ">
                                    <option  v-for="(code, key)  in this.phoneCodes" :key="key" :value="code.dial_code">{{code.code+code.dial_code}}</option>
                                </select>
                            </div>
                            <div class="col-4 ml-0 pl-0">
                                <input v-model="form.mobile" type="phone" class="mx-0 form-control blue-form" name="mobile" id="mobile">
                            </div>
                    </div>
                </div>


                <div class="form-group">
                    <div class="row blue-form">
                        <div  class="col-4 pr-0 form-label mr-1">*Preferred language:</div>
                        <div class="col-7 pl-0 ml-4"> 
                            <select v-model="form.language" class="ltr-select px-5 py-3">
                                <option value="English">English</option>
                                <option value="Spanish">Spanish</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                <div class="form-group">
                    <div class="row blue-form">
                    <label for="comments" class="col-4">Comments:</label>
                        <div class="col-8">
                            <textarea v-model="form.comments"  
                            rows="4" 
                            class="form-control comments-text" 
                            name="comments" 
                            id="comments"
                            style="background-color: #b2cee4;  color: #000000;
                                    font-weight: bold; border-color:#007bff1a;
                                    width:95%;
                                    font-size:1.6rem;
                                    border-bottom: 1px solid #BB0921;
                                    "
                            ></textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                 <div class="col-7 form-check d-flex justify-content-center">
                     <div class="align-self-center">
                        <input type="checkbox" value=true v-model="form.privacyPolicy" class="red-checkbox mx-2" id="exampleCheck1">
                        <label class="outer-label mx-1" for="exampleCheck1">
                              <a style="color: #002A49 !important;" target="_blank" href="/Aviso_de_Privacidad_de_BOOM_Entertraining.pdf"> 
                            I have read and accept the Privacy Policy
                           </a>
                        </label>
                     </div>
                </div>

                 <div class="col-5 form-check  d-flex justify-content-end pr-0">
                    <button  v-on:click.prevent="send()" :disabled="isFormfilled" :class="disabledClass" class="contact-us-button " for="exampleCheck1">SUBMIT <b-icon-triangle-fill rotate=90 class="button-arrow mb-1"> </b-icon-triangle-fill></button>
                </div>

                </div>
                
                 </b-form>
                
                </div>
               <!--enf form -->
        </div>
        </div>
        <div class="col-12 heroes-container">
                   <div class="container-fluid">
                <div class="col">
                    <div class="row d-flex">
                        <div class="col-6 col-lg-4 d-flex px-0">
                            <img class="heroe-image hero-size heroine avatar" src="@/assets/super-woman.png" />
                        </div>
                        <div class="d-none d-lg-block col-0 col-lg-4">
                            
                        </div>
                        <div class="col-6 col-lg-4 d-flex">
                            <img class="heroe-image hero-size hero avatar" src="@/assets/super-man.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
 
        <Footer/>
    </div>
</template>
<script>
// S E R V I C E S

import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import phoneCodes from "@/data/phoneCodes"

import EmailService from "@/service/email"
import WorkshopService from '@/service/workshop.js'
import ContactService from "@/service/contact"

  export default {
     components: {
       Header,
       Footer
  },
  mounted(){
      window.scrollTo(0,0);

      this.getContacts();
  },
    data() {
      return {
        workshopId:'',
        benefits: [],
        investment:[],
        isLoged:false,
        showForm: false,
        phoneCodes:[],
        workshop:{},
        formSuccess:false,
        form:{
            name:"",
            mobile:"",
            countryCode:"+52",
            email:"",
            comments:"",
            language:"",
            privacyPolicy:false,
            selectedWorkshop:"",
            subject:"Workshop"
        },
        Contacts:{
            Whatsapp: '',
            LinkiedIn: '',
            Youtube: '',
            Mobile: ''
        },
      }
    },

    created() {
    this.phoneCodes = phoneCodes.phoneCodes;
    this.getWorkshopInfo(this.$route.params.id);

  },



    watch:{
      formSuccess: function(){
        if(this.formSuccess){
          window.setTimeout(()=>{
            console.log('timeout')
            this.formSuccess = false;
            this.showForm = false;
          }, 3000);
        }
      }
    },
  computed:{

    isFormfilled(){
        if(this.form.name !="" &&
            this.form.mobile !="" &&
            this.form.email !="" &&
            this.form.comments !="" &&
            this.form.language != "" &&
            this.form.selectedWorkshop !="" &&

            this.form.privacyPolicy == true
            ){     
                return false;
            }
            return true;

    },

    disabledClass(){
        if(this.isFormfilled){
            return "disabled-button";
        }
        return "clickable";
    }

    },

    methods:{
        getWorkshopInfo(workshopId){
            WorkshopService.getWorkshopInfo(workshopId)
            .then(res => {
                this.workshop = res.data;
                this.form.selectedWorkshop = this.workshop.Title;
                this.benefits = this.workshop.Bennefits.split('\\');
                this.investment = this.workshop.Investment.split('\\');
            });
        },
        getContacts(){
        ContactService.getContacts().then((res)=>{
            this.Contacts.Whatsapp =res.data.Whatsapp;
            this.Contacts.LinkiedIn =res.data.LinkiedIn;
            this.Contacts.Youtube =res.data.Youtube;
            this.Contacts.Mobile =res.data.Mobile;
        })
    },
        codes(){
        },

        changeShowForm(){
            this.showForm = !this.showForm;
        },

        send(){
        this.form.selectedGoals = this.selectedGoals;
          EmailService.sendEmail(this.form).then(res=>{
            window.scrollTo( 0, 0 );
            console.log('exito'+ res)
            this.form={};
            this.formSuccess = true;
          }).catch(()=>{
            console.log('error')
          })
      },
    }

  }
</script>

